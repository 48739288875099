import React from 'react';
import { useIntl } from 'gatsby-plugin-intl'

import styles from './CarCard.module.css';
import LinkButton from '../../components/LinkButton/LinkButton';
import CarImage from './CarImage';


const imgSize = {width: '600px', height: '400px', borderRadius: '8px'};
const phoneImgSize = {width: '300px', height: '160px', borderRadius: '8px'};


export default function CarCard(props) {
    const intl = useIntl();
    const {
        slug,
        title,
        img,
        people,
        description,
        klasa
    } = props.data;

    return (
        <div id={title} className={styles.container}>
            <div className={styles.titleContainer}>
                <h1 className={styles.title}>{title}</h1>
                {(klasa) ? <h1 className={styles.title}>{klasa}</h1> : ''}
            </div>
            <div className={styles.descriptionContainer}>
                <div className={styles.carImgContainer}>
                    

                    <div className={styles.carImg}>
                        <CarImage alt='car image' filename={img} style={imgSize}/>
                    </div>
                    
                    <div className={styles.phoneCarImg}>
                        <CarImage alt='car image' filename={img} style={phoneImgSize}/>
                    </div>
                    

                    <div className={styles.button}>
                        <LinkButton text={intl.formatMessage({ id: 'fleetSection.morePhotos' })} theme='dark' link={`${slug}`}/>
                    </div>
                </div>
                <div className={styles.description}>    
                    <h2 className={styles.placeInCar}>{people}</h2>
                    <div dangerouslySetInnerHTML={{__html: description}} className={styles.attributesList}/>
                </div>
            </div>
        </div>
    )
}
