import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import styles from './LicensesSection.module.css'
import SectionTitle from '../../components/SectionTitle/SectionTitle'
import ZoomableImg from '../../components/ZoomableImg/ZoomableImg'
import { compareByImgName } from '../../lib/comparisons'


const imgContainerStyle = {margin: '2% 1% 0 1%'};
const imgStyle = {boxShadow: '0 16px 36px -14px black', width: '300px'};

export default function LicensesSection() {
    const intl = useIntl();
    const data = useStaticQuery(graphql`
        query {
            licenses: allFile(filter: {relativePath: {regex: "/licenses/"}}) {
                edges {
                    node {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1000) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
            certificates: allFile(filter: {relativePath: {regex: "/certificates/"}}) {
                edges {
                    node {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1000) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
            recommendations: allFile(filter: {relativePath: {regex: "/recommendations/"}}) {
                edges {
                    node {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1000) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `);


    return (
        <div id='LicensesSection' className={styles.section}>
        <SectionTitle text={intl.formatMessage({ id: 'licenses' })} font_color='black'/>
        <div className={styles.content}>
            {
                data.licenses.edges.sort(compareByImgName).map(({ node }, index) =>
                    <ZoomableImg
                        img={node}
                        containerStyle={imgContainerStyle}
                        style={imgStyle}
                        key={index}
                    />
                )
            }
        </div>
        <SectionTitle text={intl.formatMessage({ id: 'certificates' })} font_color='black'/>
        <div className={styles.content}>
            {
                data.certificates.edges.sort(compareByImgName).map(({ node }, index) =>
                    <ZoomableImg
                        img={node}
                        containerStyle={imgContainerStyle}
                        style={imgStyle}
                        key={index}
                    />
                )
            }
        </div>
        <SectionTitle text={intl.formatMessage({ id: 'recommendations' })} font_color='black'/>
        <div className={styles.content}>
            {
                data.recommendations.edges.sort(compareByImgName).map(({ node }, index) =>
                    <ZoomableImg
                        img={node}
                        containerStyle={imgContainerStyle}
                        style={imgStyle}
                        key={index}
                    />
                )
            }
        </div>
    </div>
    )
}
