import React from 'react'

import styles from './TitleSection.module.css'
import Description from './Description'
import CarSegments from './CarSegments'


export default function TitleSection() {
    return (
    <>
        <div className={styles.container}>
            <CarSegments/>
            <Description/>
        </div>
    </>
    )
}
