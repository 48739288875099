import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import Img from 'gatsby-image'

import styles from './GallerySection.module.css';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import { compareByTime } from '../../lib/comparisons';

const imgStyle = {
    width: '45%', 
    height: '45%',
    minWidth: '400px',
    minHeight: '400px', 
    margin: '0.5%', 
    boxShadow: '0 10px 8px -4px black', 
    borderBottom: '2px solid', 
    borderColor: 'black'
};

const phoneImgStyle = {
    width: '45%', 
    height: '45%',
    minWidth: '350px',
    minHeight: '300px', 
    margin: '0.5%', 
    boxShadow: '0 10px 8px -4px black', 
    borderBottom: '2px solid', 
    borderColor: 'black'
};

export default function GallerySection() {
    const intl = useIntl();
    const data = useStaticQuery(
        graphql`
            query {
                feeds: allFile(filter: {relativePath: {regex: "/feeds/"}}) {
                    edges {
                        node {
                            name
                            atime
                            childImageSharp {
                                fluid(maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    return (
        <div id='GalerySection' className={styles.container}>
            <SectionTitle text={intl.formatMessage({ id: 'gallery' })} font_color='black'/>
            <div className={styles.feedsContainer}>
                {
                    data.feeds.edges.sort(compareByTime).map(({ node }, index) => 
                        <>
                            <Img fluid={node.childImageSharp.fluid} style={imgStyle} key={index} className={styles.desktopImg}/>
                        
                            <Img fluid={node.childImageSharp.fluid} style={phoneImgStyle} key={index + 100} className={styles.phoneImg}/>
                        </>
                    )
                }
            </div>
        </div>
    )
}
