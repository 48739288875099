import React from 'react';
import { Link } from 'gatsby-plugin-intl';

import styles from './CarAvatar.module.css';
import CarImg from './CarImage';


const imgSize = {height: '200px', width: '300px', borderRadius: '8px'}


export default function CarAvatar(props) {
    const {
        title,
        img,
        people,
        klasa
    } = props.data;

    return (
        <div className={styles.container}>
            <Link to={`#${title}`}>
                <div className={styles.carImgContainer}>
                    <div className={styles.carImg}>
                        <CarImg alt='car image' filename={img} style={imgSize} imgStyle={imgSize}/>
                    </div>
                </div>
                <div className={styles.titleContainer}>
                    <h1 className={styles.title}>{klasa ? `${title} ${klasa}` : title}</h1>
                    <h2 className={styles.people}>{people}</h2>
                </div>
            </Link>
        </div>
    )
}
