import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'

import styles from './Description.module.css'


export default function Description() {
    const intl = useIntl();

    return (
        <div className={styles.container}>
            <div className={styles.headerBlock}>
                
                <h1 className={styles.headerText}>
                    <span className={styles.noWrap}><span className={styles.fbFont}>{intl.formatMessage({ id: 'titleSection.desc1' })}</span> {intl.formatMessage({ id: 'titleSection.desc2' })}</span>
                    <span className={styles.fontMargin}>{intl.formatMessage({ id: 'titleSection.carTypes' })}</span>
                </h1>

                <h1 className={styles.mobileHeaderText}>
                    <span className={styles.fbFont}>{intl.formatMessage({ id: 'titleSection.desc1' })}</span> {intl.formatMessage({ id: 'titleSection.desc2' })}
                    <div><span className={styles.fontMargin}>{intl.formatMessage({ id: 'titleSection.carTypes' })}</span></div>
                </h1>

            </div>
        </div>
    )
}
