import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'

import styles from './Details.module.css'
import PhoneElement from '../../components/PhoneElement/PhoneElement'
import UnderlineLink from '../../components/UnderlineLink/UnderlineLink'


export default function Details() {
    const intl = useIntl();
    
    return (
        <div className={styles.container}>
            
            <p className={styles.companyName}>
                {intl.formatMessage({ id: 'contactSection.details.companyName' })}
            </p>
            <p className={styles.companyLocation}>
            {intl.formatMessage({ id: 'contactSection.details.localization' })} <img alt='poland flag' src={'./poland_flag.png'} style={{height: '22px'}}/>
            </p>

            <div className={styles.contactDetails}>
                <p className={styles.contactDetailsText}>Mobile: </p>
                <PhoneElement text='+48 602 599 660' />
            </div>

            <div className={styles.contactDetails}>
                <p className={styles.contactDetailsText}>e-mail: <a href="mailto:biurograntrans@gmail.com">biurograntrans@gmail.com</a></p>
            </div>

            <div className={styles.contactForms}>
                <p className={styles.contactDetailsText}>{intl.formatMessage({ id: 'contactSection.details.or' })}</p>
                <UnderlineLink text={intl.formatMessage({ id: 'contactSection.details.fillForm' })} link='/contact-form' styles={{color: 'white', marginLeft: '5%', fontWeight: 'normal'}}/>
            </div>
        </div>
    )
}
