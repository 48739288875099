import React, { cloneElement } from 'react';


export default function reverseGrid(elem, type, direction, animData, elemOffset, leaveChildHide, ratio, paused) {
    direction = direction === 'next' ? 'prev' : 'next';
    
    const props = { ...elem.props };
    const animChild = [];
    const gridNum = 10;
    const gridWidth = elemOffset.width / gridNum;
    const gridNumH = Math.ceil(elemOffset.height / gridWidth);
    const _delay = (gridNum - 1) * 50 + (gridNumH - 1) * 50 + 200;
    if (type === 'leave') {
      props.animation = {
        ...animData,
        duration: _delay + animData.duration,
      };
      props.moment = ((animData.delay || 0) + _delay + animData.duration) * ratio || 0;
      props.paused = paused;
      return React.cloneElement(elem, props);
    }
    const moment = ratio * (animData.duration + animData.delay + _delay) || 0;
    for (let i = 0; i < gridNum * gridNumH; i++) {
      
      // mask样式
      const style = { ...props.style };
      style.position = 'absolute';
      style.overflow = 'hidden';
      style.width = `${gridWidth + 1}px`;
      style.height = `${gridWidth + 1}px`;
      style.left = i % gridNum * gridWidth;
      style.top = Math.floor(i / gridNum) * gridWidth;
      style.opacity = 0;
     
      // clone 的样式
      const _style = { ...props.style };
      _style.width = `${elemOffset.width}px`;
      _style.height = `${elemOffset.height}px`;
      _style.position = 'relative';
      _style.left = -i % gridNum * gridWidth;
      _style.top = -Math.floor(i / gridNum) * gridWidth;
      _style.overflow = 'hidden';
      props.style = _style;
      
      let delay = direction === 'next' ? i % gridNum * 50 + Math.floor(i / gridNum) * 50 :
        (gridNum - 1 - i % gridNum) * 50 + (gridNumH - 1 - Math.floor(i / gridNum)) * 50;
      delay += animData.delay || 200;
      const length = direction === 'next' ? gridNum * gridNumH - 1 : 0;
      const animation = {
        ...animData,
        opacity: 1,
        delay,
        onComplete: i === length ? animData.onComplete : null,
      };
      
      const mask = (
        <elem.type
          style={style}
          key={i}
          paused={paused}
          animation={animation}
          moment={moment}
        >
          {cloneElement(elem, props)}
        </elem.type>);
      animChild.push(mask);
    }

    const _props = { ...elem.props };
    _props.children = animChild;
    
    return cloneElement(elem, { ..._props, animation: { x: 0, y: 0, type: 'set' } });
  }
