import React from 'react';
import { useIntl } from 'gatsby-plugin-intl'

import styles from './Hint.module.css';


export default function Hint() {
    const intl = useIntl();

    return (
    <div className={styles.container}>
        <p className={styles.question}>
            {intl.formatMessage({ id: 'fleetSection.hint.question' })}
        </p>
        <p className={styles.answer}>
            {intl.formatMessage({ id: 'fleetSection.hint.answer' })}
        </p>
    </div>
    )
}
