import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import Img from 'gatsby-image'

import styles from './CertificatesSection.module.css';
import SectionTitle from '../../components/SectionTitle/SectionTitle'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import ZoomableImg from '../../components/ZoomableImg/ZoomableImg'


const imgContainerStyle = {marginTop: '2%'};
const imgStyle = {boxShadow: '0 16px 36px -14px black', width: '500px'};
const phoneImgStyle = {boxShadow: '0 16px 36px -14px black', width: '330px'};


export default function CertificatesSection() {
    const intl = useIntl();
    const data = useStaticQuery(graphql`
        query {
            cert1: file(relativePath: { eq: "certificates/cert1.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            cert2: file(relativePath: { eq: "certificates/cert2.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            bannerPRF: file(relativePath: { eq: "banner_pfr.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
    <div id='CertificatesSection' className={styles.section}>
        <SectionTitle text={intl.formatMessage({ id: 'covidSection.title' })} font_color='black'/>
        <SectionHeader text={intl.formatMessage({ id: 'covidSection.desc' })} color='black'/>
        <div className={styles.content}>
            <ZoomableImg
                img={data.cert1}
                containerStyle={imgContainerStyle}
                style={imgStyle}
            />
            <ZoomableImg
                img={data.cert2}
                containerStyle={imgContainerStyle}
                style={imgStyle}
            />
        </div>

        <div className={styles.mobileContent}>
            <ZoomableImg
                img={data.cert1}
                containerStyle={imgContainerStyle}
                style={phoneImgStyle}
            />
            <ZoomableImg
                img={data.cert2}
                containerStyle={imgContainerStyle}
                style={phoneImgStyle}
            />
        </div>

        <Img fluid={data.bannerPRF.childImageSharp.fluid} className={styles.bannerStyle}/>
    </div>
    )
}
