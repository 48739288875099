import React from 'react';
import { useIntl } from 'gatsby-plugin-intl'

import styles from './CarFleet.module.css';
import SectionTitle from '../../components/SectionTitle/SectionTitle'
import CarsGrip from './CarsGrip';
import Hint from './Hint';


export default function CarFleet() {
    const intl = useIntl();

    return (
    <div id='FleetSection' className={styles.container}>
        <SectionTitle text={intl.formatMessage({ id: 'fleetSection.title' })} font_color='black'/>
        <CarsGrip/>
        <Hint/>
    </div>
    )
}   
