import React from 'react'

import styles from './UnderlineLink.module.css'


export default function UnderlineLink(props) {
    let customStyle = {}
    let arrow = ''

    if (props.styles) {
        customStyle = props.styles
    }

    if (props.icon) {
        arrow = <h1 className={styles.arrow} style={customStyle}>&#8594;</h1>
    }

    return (
        <div className={styles.container}>
            <p className={styles.text} style={customStyle}>
                {props.text}
            </p>
            {arrow}
        </div>
    )
}
