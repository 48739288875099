import React from 'react'
import { Link } from 'gatsby-plugin-intl'

import styles from './LinkButton.module.css'


export default function LinkButton(props) {
    let themeStyles = {};
    
    if(props.theme === 'dark') {
        themeStyles = {
            backgroundColor: '#22579c',
            borderColor: '#22579c',
            color: 'white'
        }
    }

    return (
        <Link to={props.link}>
            <div className={styles.container} style={themeStyles}>
                <p className={styles.text}>{props.text}</p>
            </div>
        </Link>
    )
}
