import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import BannerAnim from 'rc-banner-anim';
import 'rc-banner-anim/assets/index.css';

import styles from './CarSegments.module.css'
import { compareByImgName } from '../../lib/comparisons'
import {
    topGridBar,
    reverseTopGridBar,
    reverseGrid,
    crossingBars,
    randomGrid,
    barAcross
} from '../../lib/animations';


const { Element, animType } = BannerAnim
const BgElement = Element.BgElement;

animType.reverseGrid = reverseGrid;
animType.topGridBar = topGridBar;
animType.reverseTopGridBar = reverseTopGridBar;
animType.crossingBars = crossingBars;
animType.randomGrid = randomGrid;
animType.barAcross = barAcross;

const desktopImgSize = {width: '950px', height: '460px'};
const phoneImgSize = {width: '375px', height: '200px'};


export default function CarSegments() {
    const data = useStaticQuery(graphql`
        query {
            cars: allFile(filter: {relativePath: {regex: "/first_page/"}}) {
                edges {
                    node {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1000) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `);
    
    return (
        <div className={styles.container}>
            <div className={styles.carousel}>
                <BannerAnim 
                    type={[
                        'grid',
                        'reverseGrid',
                        'topGridBar',
                        'reverseTopGridBar',
                        'crossingBars',
                        'randomGrid',
                        'barAcross'
                    ]}
                    prefixCls="banner-user" 
                    autoPlay 
                    style={{width: '100%', height: '100%'}} 
                    autoPlaySpeed={3000} 
                    duration={700}
                    autoPlayEffect={false}
                    dragPlay={false}
                >
                    {
                        data.cars.edges.sort(compareByImgName).map(({ node }, index) =>
                            <Element 
                                prefixCls="banner-user-elem"
                                key={`element-${index}`}
                                style={{
                                    width: '100%',
                                    height: '100%'
                                }}
                            >
                                <BgElement
                                    key={`bg-${index}`}
                                    className="bg"
                                    style={{
                                        width: '100%',
                                        height: '100%'
                                    }}
                                >
                                    <div className={styles.desktopImg}>
                                        <Img                                
                                            fluid={node.childImageSharp.fluid}
                                            style={desktopImgSize}
                                            loading='eager'
                                        />
                                    </div>
                                    <div className={styles.phoneImg}>
                                        <Img                                
                                            fluid={node.childImageSharp.fluid}
                                            style={phoneImgSize}
                                            loading='eager'
                                        />
                                    </div>
                                </BgElement>
                            </Element>
                        )
                    }
                </BannerAnim>
            </div>
        </div>
    );
}
