import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'

import styles from './AboutUs.module.css';
import SectionTitle from '../../components/SectionTitle/SectionTitle'
import PropsList from '../../components/PropsList/PropsList'


export default function AboutUs() {
    const intl = useIntl();
    
    return (
    <div id='AboutUsSection' className={styles.section}>
        <SectionTitle text={intl.formatMessage({ id: 'aboutUsSection.title' })} font_color='black'/>
        <p>
            {intl.formatMessage({ id: 'aboutUsSection.desc.p1' })}
            
            {intl.formatMessage({ id: 'aboutUsSection.desc.p2' })}
    
            {intl.formatMessage({ id: 'aboutUsSection.desc.p3' })}
    
            {intl.formatMessage({ id: 'aboutUsSection.desc.p4' })}

            {intl.formatMessage({ id: 'aboutUsSection.desc.p5' })}
    
            {intl.formatMessage({ id: 'aboutUsSection.desc.p6' })}
    
            {intl.formatMessage({ id: 'aboutUsSection.desc.p7' })}
            <br/><br/>
            {intl.formatMessage({ id: 'aboutUsSection.desc.p8' })}
            <br/><br/>
            {intl.formatMessage({ id: 'aboutUsSection.desc.p9' })}
        
            {intl.formatMessage({ id: 'aboutUsSection.desc.p10' })}
            </p>
        <div className={styles.content}>    
            <div className={styles.description}>
                <h2 className={styles.propsTitles}>{intl.formatMessage({ id: 'aboutUsSection.prioritiesTitle' })}</h2>
                <PropsList 
                    points={[
                        intl.formatMessage({ id: 'aboutUsSection.points.security' }),
                        intl.formatMessage({ id: 'aboutUsSection.points.punctuality' }),
                        intl.formatMessage({ id: 'aboutUsSection.points.satisfaction' })
                    ]}
                />
                
                <h2 className={styles.propsTitles}>{intl.formatMessage({ id: 'aboutUsSection.attributesTitle' })}</h2>
                <PropsList 
                    points={[
                        intl.formatMessage({ id: 'aboutUsSection.points.experience' }), 
                        intl.formatMessage({ id: 'aboutUsSection.points.attentions' }), 
                        intl.formatMessage({ id: 'aboutUsSection.points.punctuality' }), 
                        intl.formatMessage({ id: 'aboutUsSection.points.servicesLevel' })
                    ]}
                />
            
            </div>
        </div>
    </div>
    )
}
