import React from 'react'

import styles from './PropsList.module.css';


const extractPoints = (points) => points.map((point, index) => [<div className={styles.point} key={index}>&#10003; {point}</div>]);


export default function PropsList(props) {
    return (
    <div className={styles.container}>
        {extractPoints(props.points)}
    </div>
    )
}
