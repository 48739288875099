import React from 'react';
import { Input } from 'antd';
import { FormattedMessage } from 'gatsby-plugin-intl'

import styles from './ContactForm.module.css';


const { TextArea } = Input;

const inputTextStyle = {
    color: 'black',
    fontSize: '18px'
}


export default class ContactForm extends React.Component {
    state = {
        name: "",
        email: "",
        tel: "",
        message: ""
    }

    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        
        this.setState({
          [name]: value,
        })
      }
    
    render() {
        return (
            <form action="https://getform.io/f/cc70de05-2700-4999-9e8b-fda963561649" method="POST">
                <div className={styles.container}>
                    <label>
                        <FormattedMessage id='contactSection.form.name'/>
                        <Input
                            type="text" 
                            name="name"
                            value={this.state.fullName}
                            onChange={this.handleInputChange}
                            style={inputTextStyle}
                        />
                    </label>
                    <label>
                        <FormattedMessage id='contactSection.form.email'/>
                        <Input 
                            type="email" 
                            name="email"
                            rows={10}
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            style={inputTextStyle}
                        />
                    </label>
                    <label>
                        <FormattedMessage id='contactSection.form.tel'/>
                        <Input 
                            type="tel" 
                            name="tel"
                            rows={10}
                            value={this.state.tel}
                            onChange={this.handleInputChange}
                            style={inputTextStyle}
                        />
                    </label>
                    <label>
                        <FormattedMessage id='contactSection.form.message'/>
                        <TextArea 
                            name="message"
                            value={this.state.message}
                            rows={6}
                            onChange={this.handleInputChange}
                            style={inputTextStyle}
                        />
                    </label>
                    <div className={styles.buttonContainer}>
                        <button type="submit">
                            <FormattedMessage id='contactSection.form.sendButton'/>
                        </button>
                    </div>
                </div>
            </form>
        )
    }
}
