import customGridBar from './customGridBar';
import fallingFromTop from './fallingFromTop';
import gridFunctionFactory from './gridFunctionFactory';
import reverseGrid from './reverseGrid';
import crossingBars from './crossingBars';
import randomGrid from './randomGrid';
import barAcross from './barAcross';


const topGridBar = gridFunctionFactory('top', 'right');
const bottomGridBar = gridFunctionFactory('bottom', 'rigth');
const reverseTopGridBar = gridFunctionFactory('top', 'left');
const reverseBottomGridBar = gridFunctionFactory('bottom', 'left');


export {
    customGridBar,
    topGridBar,
    bottomGridBar,
    fallingFromTop,
    reverseTopGridBar,
    reverseBottomGridBar,
    reverseGrid,
    crossingBars,
    randomGrid,
    barAcross
};
