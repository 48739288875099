import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl';

import styles from './CarsGrip.module.css';
import CarCard from './CarCard'
import CarAvatar from './CarAvatar';
import { compareByOrderParam } from '../../lib/comparisons'


const languageFilterFactory = language => {
    return (file) => {
        return file.node.frontmatter.lang === language;
    }
}


export default function CarsGrip() {
    const data = useStaticQuery(
        graphql`
            query {
                allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/cars/"}}) {
                    edges {
                        node {
                            frontmatter {
                                img
                                title
                                people
                                klasa
                                order
                                lang
                            }
                           html
                           fields {
                               slug
                           }
                        }
                    }
                }
            }
        `
    );

    const intl = useIntl()
    const langFilter = languageFilterFactory(intl.locale)    
    const cars = data.allMarkdownRemark.edges.filter(langFilter).sort(compareByOrderParam); 

    return (
        <>
        <div className={styles.miniTableContainer}>
             {
                cars.map(({ node }, index) => 
                    <CarAvatar
                        data={{
                            title: node.frontmatter.title,
                            klasa: node.frontmatter.klasa,
                            people: node.frontmatter.people,
                            img: node.frontmatter.img
                        }}
                        key={index}
                    />
                )
            }
        </div>
        <div className={styles.container}>
            {
                cars.map(({ node }, index) => 
                    <CarCard
                        data={{
                            slug: node.fields.slug,
                            title: node.frontmatter.title,
                            klasa: node.frontmatter.klasa,
                            img: node.frontmatter.img,
                            people: node.frontmatter.people,
                            description: node.html
                        }}
                        key={index}
                    />
                )
            }
        </div>
        </>
    )
}
