import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'

import styles from './Contact.module.css'
import SectionTitle from '../../components/SectionTitle/SectionTitle'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import Details from './Details'
import ContactForm from './ContactForm'


export default function Contact() {
    const intl = useIntl();

    return (
        <div id='ContactSection' className={styles.container}>
            <SectionTitle text={intl.formatMessage({ id: 'contactSection.title' })}/>
            <SectionHeader text={intl.formatMessage({ id: 'contactSection.desc' })}/>

            <div className={styles.detailsContainer}>
                <Details/>
                <ContactForm/>
            </div>
        </div>
    )
}
