import React from 'react'

import styles from './PhoneElement.module.css';


const blackPhonePath = '../../../phone.png'
const whitePhonePath = '../../../phone_white.png'


export default function PhoneElement(props) {
    let themeStyles = {};
    let icon = blackPhonePath;
    
    if(props.theme === 'dark') {
        themeStyles = {
            backgroundColor: 'black',
            borderColor: 'black',
            color: 'white'
        }
        icon = whitePhonePath;
    }

    return (
    <div className={styles.container} style={themeStyles}>
        <img src={icon} alt="icon" className={styles.icon}/>
        <p className={styles.text}><a href="tel:+48602599660">{props.text}</a></p>
    </div>
    )
}
