import React, { cloneElement } from 'react';


export default function barAcross(elem, type, direction, animData, elemOffset, leaveChildHide, ratio, paused) {
    const props = { ...elem.props };
    const animChild = [];
    const gridNum = 20;
    const girdSize = 100 / gridNum;
    let _x;
    const children = props.children;
    
    if (type === 'enter') {
        _x = direction === 'next' ? '0%' : '0%';
    } else {
        _x = direction === 'next' ? '0%' : '0%';
    }
    const moment = ratio * (animData.duration + animData.delay + gridNum * 50 - (type === 'enter' ? 50 : 0)) || 0;
    
    for (let i = 0; i < gridNum; i++) {
        
        const style = { ...props.style };
        style.width = `${girdSize + 0.1}%`;
        style.left = `${i * girdSize}%`;
        style.position = 'absolute';
        style.overflow = 'hidden';
        style.opacity = 1;

        const _style = { ...props.style };
        _style.width = `${elemOffset.width}px`;
        _style.height = `${elemOffset.height}px`;
        _style.float = 'left';
        _style.position = 'relative';
        _style.left = `${-i * girdSize / 100 * elemOffset.width}px`;
        _style.overflow = 'hidden';
        const animProps = { ...props };
        animProps.style = _style;
        
        const delay = (direction === 'next' ? i : gridNum - i) * (10 * (i/ 2)) + (type === 'enter' ? 0 : 50) + (animData.delay || 200);
        animProps.animation = {
        ...animData,
        x: _x,
        opacity: 0,
        type: type === 'enter' ? 'from' : 'to',
        key: type,
        direction,
        delay,
        i,
        onComplete: i === (direction === 'next' ? gridNum - 1 : 0) ?
            animData.onComplete : null
        };
        animProps.paused = paused;
        animProps.moment = moment;
        
        const mask = (
            <elem.type
                style={style} 
                key={i}
                animation={animProps.animation} 
                paused={paused}
                moment={moment}
            >
                {cloneElement(elem, animProps, children)}
            </elem.type>
        );
        animChild.push(mask);
    }

    const _props = { ...elem.props };
    _props.children = animChild;
    return cloneElement(elem, { ..._props, animation: { x: 0, y: 0, type: 'set' } });
};
