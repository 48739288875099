import React from "react"
import { injectIntl } from 'gatsby-plugin-intl'

import Layout from '../sections/Layout/Layout'
import TitleSection from '../sections/TitleSection/TitleSection'
import Offer from '../sections/OfferSection/Offer'
import CarFleet from '../sections/CarFleetSection/CarFleet'
import Contact from '../sections/ContactSection/Contact'
import AboutUs from '../sections/AboutUsSection/AboutUs'
import FooterBar from '../sections/FooterBar/FooterBar'
import GallerySection from "../sections/GallerySection/GallerySection"
import CertificatesSection from "../sections/CertificatesSection/CertificatesSection"
import LicensesSection from "../sections/LicensesSection/LicensesSection"


const Home = ({ intl }) => {
  return (
    <Layout>
      <div style={{display: 'flex', flexFlow: 'column wrap', alignItems: 'center', backgroundImage: 'url("back-pattern.png")'}}>
        <TitleSection/>
        <CertificatesSection/>
        <Offer/>
        <CarFleet/>
        <Contact/>
        <AboutUs/>
        <LicensesSection/>
        <GallerySection/>
      </div>
      <FooterBar/>
    </Layout>
  )
}

export default injectIntl(Home);
