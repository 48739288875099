import React from 'react'

import styles from './SectionTitle.module.css'


export default function SectionTitle(props) {
    const theme_styles = {}
    
    if (props.font_color) {
        theme_styles.color = props.font_color;
    }
    
    return (
    <div className={styles.container}>
        <h1 className={styles.text} style={theme_styles}>{props.text}</h1>
    </div>
    )
}
