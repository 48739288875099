import React from 'react';
import BannerAnim from 'rc-banner-anim';


const { setAnimCompToTagComp  } = BannerAnim


export default function fallingFromTop(elem, type, direction, animData, elemOffset, leaveChildHide, ratio, paused) {
    // type: enter or leave
    // direction: next or prev

    let _y;
    const props = { ...elem.props };
    let children = props.children;
    
    if (type === 'enter') {
        _y = direction === 'next' ? '-100%' : '100%';
    } else {
        _y = direction === 'next' ? '10%' : '-10%';
        children = React.Children.toArray(children).map(setAnimCompToTagComp);
    }
    
    return React.cloneElement(elem, {
        ...props,
        animation: {
            ...animData,
            y: _y,
            type: type === 'enter' ? 'from' : 'to',
        },
    });
};
