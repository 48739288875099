import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'

import styles from './Offer.module.css';
import SectionTitle from '../../components/SectionTitle/SectionTitle'
import SectionHeader from '../../components/SectionHeader/SectionHeader';


export default function Offer() {
    const intl = useIntl();

    return (
    <div id='OffersSection' className={styles.container}>
        <SectionTitle text={intl.formatMessage({ id: 'offerSection.title' })}/>
        <SectionHeader text={intl.formatMessage({ id: 'offerSection.desc' })}/>
        
        <div className={styles.offersContainer}>
            <div className={styles.offersListContainer}>
                <h2>{intl.formatMessage({ id: 'offerSection.individualClients.title' })}</h2>
                <ul className={styles['offersList']}>
                    <li>{intl.formatMessage({ id: 'offerSection.individualClients.item1' })}</li>
                    <li>{intl.formatMessage({ id: 'offerSection.individualClients.item2' })}</li>
                    <li>{intl.formatMessage({ id: 'offerSection.individualClients.item3' })}</li>
                    <li>{intl.formatMessage({ id: 'offerSection.individualClients.item4' })}</li>
                    <li>{intl.formatMessage({ id: 'offerSection.individualClients.item5' })}</li>
                    <li>{intl.formatMessage({ id: 'offerSection.individualClients.item6' })}</li>            
                </ul>
            </div>
            
            <div className={styles.offersListContainer}>
                <h2>{intl.formatMessage({ id: 'offerSection.groups.title' })}</h2>
                <ul className={styles['offersList']}>
                    <li>{intl.formatMessage({ id: 'offerSection.groups.item1' })}</li>
                    <li>{intl.formatMessage({ id: 'offerSection.groups.item2' })}</li>
                    <li>{intl.formatMessage({ id: 'offerSection.groups.item3' })}</li>
                </ul>
            </div>            
        </div>

        <p className={styles['offersDescription']}>{intl.formatMessage({ id: 'offerSection.hint' })}</p>
    </div>
    )
}
