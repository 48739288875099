import React from 'react'

import styles from './SectionHeader.module.css'


export default function SectionHeader(props) {
    let customStyles = {};
    
    if(props.color) {
        customStyles.color = props.color;
    }

    if(props.fontSize) {
        customStyles.fontSize = props.fontSize;
    }

    return (
        <div className={styles.container}>
            <p className={styles.text} style={customStyles}>
                {props.text}
            </p>
        </div>
    )
}
